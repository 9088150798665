<template>
  <v-dialog
    v-model="dialog"
    class="dialog font-weight-medium"
    max-width="600"
    persistent
  >
    <v-card class="card py-10">
      <h2 class="text-center font-weight-regular">
        {{ $t("sure_billing") }}
      </h2>
      <v-row>
        <v-col class="mt-7 mb-4 text-center" cols="12">
          <v-btn
            class="mr-5 font-weight-bold"
            style="box-shadow: none"
            @click="$emit('makeForBilling')"
            height="42"
            :loading="isSelecting"
            >{{ $t("yes") }}
          </v-btn>
          <v-btn
            class="button font-weight-bold"
            height="42"
            @click="$emit('cancel')"
            >{{ $t("no") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MakeSureSelectForBiling",
  props: {
    dialog: Boolean,
    isSelecting: Boolean,
  },
};
</script>

<style scoped></style>
